<template>
  <div>
    <iframe src="https://dev-donut.ais.co.th/loyalty/"> </iframe>
    <br />
    <button class="fontcolor" onclick="loginMyAIS()">LOGIN</button>
    <button class="fontcolor" onclick="closeWebView()">CLOSE WEB VIEW</button>
    <v-btn
      class="btnFont mt-5 mr-2"
      color="#B2D234"
      href="https://myais.ais.co.th/openAppToApp?URI=myais%3A%2F%2FPrivilegeList%3Ftype%3DPOINT%26id%3D12"
      >OPEN SHOPPING MY AIS</v-btn
    >
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
