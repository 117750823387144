<template>
  <v-app>
    <v-app-bar app color="white">
      <v-container class="d-flex align-center contain">
        <router-link to="/">
          <v-img
            alt="devio Logo"
            class="shrink mr-2"
            contain
            src="../assets/logoblack.png"
            transition="scale-transition"
            max-width="100"
        /></router-link>
        <v-spacer></v-spacer>
        <v-menu v-if="checkSize()" bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon class="ma-0 pa-0" v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><v-btn text color="black" @click="gotoDiv(1)"
                    >What's DEVIO Beacon?</v-btn
                  ></v-list-item-title
                >
                <v-list-item-title
                  ><v-btn text color="black" @click="gotoDiv(2)"
                    >Features</v-btn
                  ></v-list-item-title
                >
                <v-list-item-title
                  ><v-btn text color="black" @click="gotoDiv(3)"
                    >Specification</v-btn
                  ></v-list-item-title
                >
                <v-list-item-title
                  ><v-btn text color="black" @click="gotoDiv(4)"
                    >Pricing</v-btn
                  ></v-list-item-title
                >
                <v-list-item-title
                  ><v-btn text color="black" @click="gotoDiv(5)"
                    >Contact us</v-btn
                  ></v-list-item-title
                >
                <v-list-item-title
                  ><v-btn
                    outlined
                    color="black"
                    href="https://beacon.aisdevio.com:8080/"
                    target="_blank"
                    >Devio Connext</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else>
          <v-btn text color="black" @click="gotoDiv(1)"
            >What's DEVIO Beacon?</v-btn
          >
          <v-btn text color="black" @click="gotoDiv(2)">Features</v-btn>
          <v-btn text color="black" @click="gotoDiv(3)">Specification</v-btn>
          <v-btn text color="black" @click="gotoDiv(4)">Pricing</v-btn>
          <v-btn text color="black" @click="gotoDiv(5)">Contact us</v-btn>
          <v-btn
            outlined
            color="black"
            href="https://beacon.aisdevio.com:8080/"
            target="_blank"
            >Devio Connext</v-btn
          >
        </div>
      </v-container>
    </v-app-bar>
    <v-main class="main">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import mRouted from '../globalfunction/route';
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({}),
  methods: {
    routed(destImpl) {
      mRouted(destImpl, this.$router);
    },
    gotoDiv(id) {
      const yOffset = -75;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "DBHeavent";
  src: local("DBHeavent"),
    url("../font/DB Heavent Med v3.2.ttf") format("truetype");
}
#app {
  font-family: DBHeavent;
  font-style: normal;
}
html {
  font-family: DBHeavent;
  font-style: normal;
}
.main {
  background: #ffffff;
}
</style>
