<template>
  <v-footer padless dark>
    <v-card class="flex" flat tile>
      <v-card class="flex pa-10" flat tile color="#1A1B23">
        <h1 class="titleFont text-center mb-5">CONTACT US</h1>
        <v-row class="ma-0" align="center" justify="center"
          ><v-avatar class="mr-2" size="32"
            ><img src="../assets/btn/facebook.png"
          /></v-avatar>
          <a
            class="footerFont mr-5"
            href="https://facebook.com/AISDEVIO"
            target="_blank"
            >DEVIO</a
          >
          <v-avatar class="mr-2" size="32"
            ><img src="../assets/btn/email.png"
          /></v-avatar>
          <div class="footerFont">aiap@ais.co.th</div>
        </v-row>
      </v-card>
      <v-card class="pl-15 pa-2" flat tile color="black">
        <v-container class="smallcontain">
          <v-row class="ma-0">
            <router-link to="/">
              <v-img
                alt="devio Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                max-width="70"
              ></v-img
            ></router-link>
            <v-spacer></v-spacer>
            <div>
              <v-icon class="mr-1" small>mdi-arrow-collapse-down</v-icon
              ><a
                class="customa"
                href="https://sites.google.com/view/devioconnext/"
                target="_blank"
                >USER GUIDE</a
              >
            </div></v-row
          >
        </v-container>
      </v-card>
    </v-card>
  </v-footer>
</template>

<script>
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({ publicPath: process.env.BASE_URL }),
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>

<style>
.footerFont {
  font-size: 1.5em;
  color: white !important;
  text-decoration: none;
}
.footerFont:hover {
  font-size: 1.5em;
  color: white !important;
  text-decoration: underline !important;
}
.customa {
  color: white !important;
  text-decoration: none;
}
.customa:hover {
  color: white !important;
  text-decoration: underline !important;
}
</style>
