import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Default from './layouts/default.vue';
import Blank from './layouts/blank.vue';
import Devtoolbar from './layouts/devtoolbar.vue';
import Bistoolbar from './layouts/bistoolbar.vue';

Vue.component('default-layout', Default);
Vue.component('blank-layout', Blank);
Vue.component('devtoolbar-layout', Devtoolbar);
Vue.component('bistoolbar-layout', Bistoolbar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
