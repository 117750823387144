<template>
  <div>
    <v-container v-if="checkSize()" class="contain mt-8">
      <v-row
        ><v-col cols="12"
          ><h1 class="devioFont text-center">DEVIO BEACON SOLUTION</h1></v-col
        ><v-col class="align-self-center">
          <v-img
            class="mx-auto"
            max-height="513"
            max-width="610"
            src="../assets/pic/retailer.png"
          ></v-img>
          <h3 class="subtitleFont text-center">
            CONNECT WITH CUSTOMERS AT THE RIGHT TIME AND PLACE
          </h3>
          <div class="normalFont text-center">
            โซลูชันใหม่ในการเชื่อมโยงธุรกิจของคุณกับลูกค้าให้
            <strong>‘ถูกที่ ถูกเวลา’</strong>
          </div></v-col
        ></v-row
      >
      <v-row class="mt-10"></v-row>
      <v-row class="mt-10"
        ><v-col cols="12" class="align-self-center"
          ><h1 class="titleFont">WHAT'S DEVIO BEACON</h1>
          <div class="normalFont mt-1">
            ตัวช่วยใหม่สำหรับธุรกิจร้านค้าในการเพิ่มยอดขาย และสื่อสารกับลูกค้า
            กลุ่มเป้าหมายที่เดินผ่านหน้าร้าน ผ่าน LINE Official Account
            เพื่อกระตุ้นการตัดสินใจได้แบบ Real time
          </div>
          <div>
            <v-btn
              class="btnFont mt-5 mr-2"
              color="#B2D234"
              href="https://www.ais.th/consumers/store/accessories/devio/devio-beacon?capacity=&color=&campaign=0000&compCode=1&plan=&extsrc=false"
              target="_blank"
              >BUY NOW</v-btn
            >
            <v-btn class="fontcolor mt-5" color="black" @click="gotoDiv(4)">PRICING</v-btn>
          </div></v-col
        ><v-col
          ><video
            class="vidTemplate"
            src="../assets/video/DevioBeaconVDOAds_vAnimation_forRetailers.mp4"
            poster="../assets/video/VDO-Thumbnail_vAnimation for retailers_20211006.png"
            controls
          ></video></v-col
      ></v-row>
    </v-container>
    <v-container v-else class="contain">
      <v-row
        ><v-col class="d-md-flex justify-end"
          ><v-img
            max-height="513"
            max-width="610"
            src="../assets/pic/retailer.png"
          ></v-img></v-col
        ><v-col class="align-self-center"
          ><h1 class="devioFont">DEVIO BEACON SOLUTION</h1>
          <h3 class="subtitleFont">
            CONNECT WITH CUSTOMERS AT THE RIGHT TIME AND PLACE
          </h3>
          <div class="normalFont">
            โซลูชันใหม่ในการเชื่อมโยงธุรกิจของคุณกับลูกค้าให้
            <strong>‘ถูกที่ ถูกเวลา’</strong>
          </div></v-col
        ></v-row
      >
      <v-row
        ><v-col class="align-self-center colClass"
          ><h1 class="titleFont">WHAT'S DEVIO BEACON</h1>
          <div class="normalFont">
            ตัวช่วยใหม่สำหรับธุรกิจร้านค้าในการเพิ่มยอดขาย และสื่อสารกับลูกค้า
            กลุ่มเป้าหมายที่เดินผ่านหน้าร้าน ผ่าน LINE Official Account
            เพื่อกระตุ้นการตัดสินใจได้แบบ Real time
          </div>
          <div>
            <v-btn
              class="btnFont mt-5 mr-2"
              color="#B2D234"
              href="https://www.ais.th/consumers/store/accessories/devio/devio-beacon?capacity=&color=&campaign=0000&compCode=1&plan=&extsrc=false"
              target="_blank"
              >BUY NOW</v-btn
            >
            <v-btn class="fontcolor mt-5" color="black" @click="gotoDiv(4)">PRICING</v-btn>
          </div></v-col
        ><v-col
          ><video
            class="vidTemplate"
            src="../assets/video/DevioBeaconVDOAds_vAnimation_forRetailers.mp4"
            poster="../assets/video/VDO-Thumbnail_vAnimation for retailers_20211006.png"
            controls
          ></video></v-col
      ></v-row>
    </v-container>
  </div>
</template>

<script>
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({}),
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
    gotoDiv(id) {
      const yOffset = -65;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
</script>

<style>
.devFont {
  color: #91b407;
}
</style>
