<template>
  <v-container class="smallcontain mt-8">
    <v-row v-if="checkSize()"
      ><v-col cols="12"
        ><video
            class="vidTemplate"
            src="../assets/video/DEVIO_preview CMS.mp4"
            autoplay
            loop
          ></video></v-col>
      <v-col cols="12" class="align-self-center"
        ><h1 class="titleFont">DEVIO CONNEXT</h1>
        <ul class="normalFont mt-1">
          <li>
            ร้านค้าสามารถเลือกส่งข้อความได้หลากหลายรูปแบบ ทั้งข้อความ รูปภาพ
            วิดีโอและลิงก์
          </li>
          <li>
            สามารถส่งข้อความถึงลูกค้าที่อยู่ภายในระยะรัศมี 25 เมตร
            ของอุปกรณ์ได้แบบอัตโนมัติ ผ่าน LINE Official Account
          </li>
          <li>
            ร้านค้าสามารถสร้างแคมเปญที่แตกต่างกันตามแต่ละสาขา
            หรือสถานที่ที่ติดตั้งอุปกรณ์ได้
          </li>
          <li>
            สามารถกำหนดแคมเปญล่วงหน้า รวมถึงกำนหนดช่วงเวลา
            และความถี่ในการส่งข้อความถึงลูกค้าได้
          </li>
        </ul>
      </v-col></v-row
    >
    <v-row v-else
      ><v-col class="align-self-center"
        ><h1 class="titleFont">DEVIO CONNEXT</h1>
        <ul class="normalFont">
          <li>
            ร้านค้าสามารถเลือกส่งข้อความได้หลากหลายรูปแบบ ทั้งข้อความ รูปภาพ
            วิดีโอและลิงก์
          </li>
          <li>
            สามารถส่งข้อความถึงลูกค้าที่อยู่ภายในระยะรัศมี 25 เมตร
            ของอุปกรณ์ได้แบบอัตโนมัติ ผ่าน LINE Official Account
          </li>
          <li>
            ร้านค้าสามารถสร้างแคมเปญที่แตกต่างกันตามแต่ละสาขา
            หรือสถานที่ที่ติดตั้งอุปกรณ์ได้
          </li>
          <li>
            สามารถกำหนดแคมเปญล่วงหน้า รวมถึงกำนหนดช่วงเวลา
            และความถี่ในการส่งข้อความถึงลูกค้าได้
          </li>
        </ul> </v-col
      ><v-col
          ><video
            class="vidTemplate"
            src="../assets/video/DEVIO_preview CMS.mp4"
            autoplay
            loop
          ></video></v-col
      ></v-row>
  </v-container>
</template>

<script>
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({}),
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>
