import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Developer from '../views/DeveloperPortal.vue';
import Business from '../views/BusinessPortal.vue';
import Test from '../views/Test.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Developer',
    name: 'Developer',
    component: Developer,
    meta: { layout: 'devtoolbar' },
  },
  {
    path: '/Business',
    name: 'Business',
    component: Business,
    meta: { layout: 'bistoolbar' },
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
