function isMobile(screensize) {
  switch (screensize) {
    case 'xs':
      return true;
    case 'sm':
      return true;
    case 'md':
      return false;
    case 'lg':
      return false;
    case 'xl':
      return false;
    default:
      return false;
  }
}

export default isMobile;
