<template>
  <div>
    <div v-if="!checkSize()" class="div-solution-bg">
      <TemplateBis id="1" />
      <Benefits id="2" />
    </div>
    <div v-if="!checkSize()" class="div-solution2-bg">
      <Feature id="3" />
      <DevioConnext />
    </div>
    <div v-else class="div-solution-mobile-bg">
      <TemplateBis id="1" />
      <Benefits id="2" />
      <Feature id="3" />
      <DevioConnext />
    </div>
    <Pricing id="4" />
    <Footer id="5" />
  </div>
</template>

<script>
import TemplateBis from '../components/templateBis.vue';
import Feature from '../components/featureBis.vue';
import Benefits from '../components/benefits.vue';
import DevioConnext from '../components/devioDetails.vue';
import Pricing from '../components/bisPricing.vue';
import Footer from '../components/footer.vue';
import isMobile from '../globalfunction/screensize';

export default {
  components: {
    TemplateBis,
    Feature,
    Benefits,
    DevioConnext,
    Pricing,
    Footer,
  },
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>

<style>
.div-solution-bg {
  background-image: url("../assets/BG_solution_top.jpg");
  background-size: cover;
}
.div-solution2-bg {
  background-image: url("../assets/BG_solution_bottom.jpg");
  background-size: cover;
}
.div-solution-mobile-bg {
  background-image: url("../assets/BG_mobile_DEVIO Beacon solution.jpg");
  background-size: 100%;
}
</style>
