<template>
  <v-container class="contain mt-8">
    <v-row v-if="checkSize()"
      ><v-col cols="12">
        <v-img
          class="mx-auto"
          src="../assets/pic/Work-with-LINE-Beacon.png"
          max-width="200"
        ></v-img>
        <v-img src="../assets/pic/solution-3.png" max-width="350"></v-img>
        <div class="mt-5 text-center normalFont">
          นักพัฒนาสามารถสร้างสรรค์ Solution ใหม่ ร่วมกับ DEVIO Beacon ได้ง่ายๆ
          ผ่าน LINE API
        </div>
        <div class="mt-5 text-center">
          <v-btn
            class="mt-5 white--text btnFont"
            color="#00BA19"
            href="https://developers.line.biz/en/docs/messaging-api/using-beacons/"
            target="_blank"
            >LEARN MORE</v-btn
          >
        </div></v-col
      ></v-row
    >
    <v-row v-else
      ><v-col cols="4">
        <v-img
          src="../assets/pic/Work-with-LINE-Beacon.png"
          max-width="300"
        ></v-img>
        <div class="mt-5 normalFont">
          นักพัฒนาสามารถสร้างสรรค์ Solution ใหม่ ร่วมกับ DEVIO Beacon ได้ง่ายๆ
          ผ่าน LINE API
        </div>
        <v-btn
          class="btnFont mt-5 white--text"
          color="#00BA19"
          href="https://developers.line.biz/en/docs/messaging-api/using-beacons/"
          target="_blank"
          >LEARN MORE</v-btn
        ></v-col
      ><v-col cols="8" class="align-self-center"
        ><v-img
          src="../assets/pic/solution-3.png"
          max-width="700"
        ></v-img></v-col
    ></v-row>
  </v-container>
</template>

<script>
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({}),
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>
