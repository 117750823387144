<template>
  <v-app>
    <v-app-bar app color="black" dark>
      <v-container class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="devio Logo"
            class="shrink mr-2"
            contain
            src="../assets/logo.png"
            transition="scale-transition"
            width="100"
        /></router-link>
      </v-container>
    </v-app-bar>
    <v-main class="main">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import mRouted from '../globalfunction/route';

export default {
  data: () => ({}),
  methods: {
    routed(destImpl) {
      mRouted(destImpl, this.$router);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "DBHeavent";
  src: local("DBHeavent"),
   url("../font/DB Heavent Med v3.2.ttf") format("truetype");
}
#app {
  font-family: DBHeavent;
  font-style: normal;
}
html {
  font-family: DBHeavent;
  font-style: normal;
}
.main {
  background: #ffffff;
}
</style>
