<template>
  <div class="chooseBG">
    <ChooseBtn />
  </div>
</template>

<script>
import ChooseBtn from '../components/chooseBtn.vue';

export default {
  components: {
    ChooseBtn,
  },
};
</script>

<style>
.chooseContain {
  max-width: 900px;
}
.chooseBG {
  background-image: url("../assets/homebg.png");
  height: 100%;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 50%;
}
</style>
