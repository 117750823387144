<template>
  <v-container class="chooseContain" fill-height>
    <v-row align="center">
      <v-col cols="12" sm="6"
        ><v-hover v-slot="{ hover }"
          ><v-card
            :elevation="hover ? 16 : 0"
            class="pa-10 ma-5 mx-auto"
            max-width="130%"
            max-height="100%"
            color="#F0F0F0"
            @click="routed('Developer')"
            ><v-img max-height="285" src="../assets/btn/devBtn.png"></v-img>
            <div class="text-center">
              <h2 class="choosetitle-font">DEVIO BEACON</h2>
              <div class="choosesub-font">for Developer</div>
            </div>
          </v-card></v-hover
        ></v-col
      >
      <v-col cols="12" sm="6"
        ><v-hover v-slot="{ hover }"
          ><v-card
            class="pa-6 ma-5 mx-auto"
            max-width="130%"
            max-height="100%"
            color="#F0F0F0"
            :elevation="hover ? 16 : 0"
            @click="routed('Business')"
            ><v-img
              class="pa-5"
              max-height="285"
              src="../assets/btn/bisBtn.png"
            ></v-img>
            <div class="text-center">
              <h2 class="choosetitle-font">DEVIO BEACON</h2>
              <h2 class="choosetitle-font">SOLUTION</h2>
              <div class="choosesub-font">for Business</div>
            </div></v-card
          ></v-hover
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import mRouted from '../globalfunction/route';

export default {
  data: () => ({}),
  methods: {
    routed(destImpl) {
      mRouted(destImpl, this.$router);
    },
  },
};
</script>

<style>
.choosetitle-font {
  font-size: 2.5em;
  letter-spacing: 0.5px;
  line-height: 34px;
}
.choosesub-font {
  font-size: 2em;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 34px;
}
</style>
