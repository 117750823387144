function switchpage(destination, router, body) {
  const go = {
    name: destination,
    params: {
      status: 'ok',
      page: destination,
    },
    query: body,
  };
  router.push(go);
  return null;
}
export default switchpage;
