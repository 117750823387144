<template>
  <div>
    <div v-if="!checkSize()" class="divBg">
      <TemplateDev id="1" />
      <Feature id="2" />
      <Specific id="3" />
    </div>
    <div v-else class="divmobileBg">
      <TemplateDev id="1" />
      <Feature id="2" />
      <Specific id="3" />
    </div>
    <LineBeacon />
    <Pricing id="4" />
    <Footer id="5" />
  </div>
</template>

<script>
import TemplateDev from '../components/templateDev.vue';
import Feature from '../components/features.vue';
import Specific from '../components/specification.vue';
import LineBeacon from '../components/lineBeacon.vue';
import Pricing from '../components/devPricing.vue';
import Footer from '../components/footer.vue';
import isMobile from '../globalfunction/screensize';

export default {
  components: {
    TemplateDev,
    Feature,
    Specific,
    LineBeacon,
    Pricing,
    Footer,
  },
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
  },
};
</script>

<style>
.divBg {
  background-image: url("../assets/BG2.jpg");
  background-size: cover;
}
.divmobileBg {
  background-image: url("../assets/BG_mobile.jpg");
  background-size: 100%;
}
</style>
