<template>
  <div>
    <v-container v-if="checkSize()" class="contain mt-8">
      <v-row
        ><v-col cols="12"
          ><h1 class="devioFont text-center">DEVIO BEACON</h1></v-col
        ><v-col cols="12" class="align-self-center">
          <v-img
            class="mx-auto"
            max-height="513"
            max-width="610"
            src="../assets/btn/devBtn.png"
          ></v-img>
          <h3 class="subtitleFont text-center">
            THE POSSIBILITY IS ONLY LIMITED BY YOUR IMAGINATION
          </h3>
          <div class="normalFont text-center">
            สุดยอด Solution แห่งการเชื่อมต่อสามารถเกิดขึ้นจริงได้ ด้วย
            <strong>‘จินตนาการที่ไร้ขีดจำกัดของคุณ’</strong>
          </div></v-col
        ></v-row
      >
      <v-row class="mt-10"></v-row>
      <v-row class="mt-10"
        ><v-col cols="12"
          ><h1 class="titleFont">WHAT'S DEVIO BEACON</h1>
          <div class="normalFont mt-1">
            อุปกรณ์ฮาร์ดแวร์ที่สามารถรับส่งข้อมูลได้ ผ่าน LINE Official Account
            ช่วยพัฒนารูปแบบการเชื่อมต่อระหว่างออฟไลน์
            และออนไลน์ได้อย่างไม่มีที่สิ้นสุด
          </div>
          <div>
            <v-btn
              class="btnFont mt-5 mr-2"
              color="#B2D234"
              href="https://www.ais.th/consumers/store/accessories/devio/devio-beacon?capacity=&color=&campaign=0000&compCode=1&plan=&extsrc=false"
              target="_blank"
              >BUY NOW</v-btn
            >
            <v-btn class="fontcolor mt-5" color="black" @click="gotoDiv(4)"
              >PRICING</v-btn
            >
          </div></v-col
        ><v-col cols="12"
          ><video
            class="vidTemplate"
            src="../assets/video/Devio-Line-Beacon_Product-reveal-teaser.mp4"
            poster="../assets/video/VDO-Thumbnail_v3D Official Trailer 60sec_20211006.png"
            controls
          ></video></v-col
      ></v-row>
    </v-container>
    <v-container v-else class="contain">
      <v-row
        ><v-col class="d-md-flex justify-end"
          ><v-img
            max-height="513"
            max-width="610"
            src="../assets/btn/devBtn.png"
          ></v-img></v-col
        ><v-col class="align-self-center"
          ><h1 class="devioFont">DEVIO BEACON</h1>
          <h3 class="subtitleFont">
            THE POSSIBILITY IS ONLY LIMITED BY YOUR IMAGINATION
          </h3>
          <div class="normalFont">
            สุดยอด Solution แห่งการเชื่อมต่อสามารถเกิดขึ้นจริงได้ ด้วย
            <strong>‘จินตนาการที่ไร้ขีดจำกัดของคุณ’</strong>
          </div></v-col
        ></v-row
      >
      <v-row
        ><v-col class="colClass align-self-center"
          ><h1 class="titleFont">WHAT'S DEVIO BEACON</h1>
          <div class="normalFont">
            อุปกรณ์ฮาร์ดแวร์ที่สามารถรับส่งข้อมูลได้ ผ่าน LINE Official Account
            ช่วยพัฒนารูปแบบการเชื่อมต่อระหว่างออฟไลน์
            และออนไลน์ได้อย่างไม่มีที่สิ้นสุด
          </div>
          <div>
            <v-btn
              class="btnFont mt-5 mr-2"
              color="#B2D234"
              large
              href="https://www.ais.th/consumers/store/accessories/devio/devio-beacon?capacity=&color=&campaign=0000&compCode=1&plan=&extsrc=false"
              target="_blank"
              >BUY NOW</v-btn
            >
            <v-btn
              class="fontcolor mt-5"
              color="black"
              large
              @click="gotoDiv(4)"
              >PRICING</v-btn
            >
          </div></v-col
        ><v-col
          ><video
            class="vidTemplate"
            src="../assets/video/Devio-Line-Beacon_Product-reveal-teaser.mp4"
            poster="../assets/video/VDO-Thumbnail_v3D Official Trailer 60sec_20211006.png"
            controls
          ></video></v-col
      ></v-row>
    </v-container>
  </div>
</template>

<script>
import isMobile from '../globalfunction/screensize';

export default {
  data: () => ({}),
  methods: {
    checkSize() {
      return isMobile(this.$vuetify.breakpoint.name);
    },
    gotoDiv(id) {
      const yOffset = -75;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
</script>

<style>
@media only screen and (min-width: 2560px) {
  .contain {
    max-width: 1500px;
  }
}
@media only screen and (max-width: 2559px) {
  .contain {
    max-width: 1200px;
  }
}

.vidTemplate {
  max-width: 100%;
  max-height: 100%;
}
.devioFont {
  color: #91b407;
  font-size: 3em;
  line-height: 50px;
}
.titleFont {
  font-size: 3em;
  line-height: 45px;
}
.subtitleFont {
  font-size: 2em;
  line-height: 34px;
}
.normalFont {
  font-size: 1.3em;
  line-height: 32px;
}
.btnFont {
  font-size: 1em !important;
  font-weight: bold !important;
}
.fontcolor {
  font-size: 1em !important;
  color: #b2d234 !important;
  font-weight: bold !important;
}
.colClass {
  max-width: 500px;
}
</style>
