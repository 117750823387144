<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
const defaultLayout = 'default';
export default {
  name: 'App',
  created() {
    document.title = 'AISDEVIO';
  },
  computed: {
    layout() {
      // console.log(this.$route.meta);
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
};
</script>
